import style from "./Footer.module.scss";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <div className={style.container}>

            <div className={style.infoContainer}>
                <div className={style.item}>
                    <div className={style.logo}>
                        <NavLink to="/">
                            <img src={logo} alt="" />
                        </NavLink>
                    </div>
                    <div className={style.navContainer}>
                        <div className={style.navLinkContainer}>
                            <div className={style.linkGroup}>
                                <NavLink to="/uber-uns">
                                    Über Uns
                                </NavLink >
                                <NavLink to="/produkte">
                                    Produkte
                                </NavLink >
                            </div>
                            <div className={style.linkGroup}>
                                <NavLink to="/impressum">
                                    Impressum
                                </NavLink >
                                <NavLink to="/kontakt">
                                    Kontakt
                                </NavLink >
                            </div>

                        </div>
                    </div>
                </div>
                <div className={style.item}>
                    <p>
                        Picola GmbH
                    </p>
                    <p>
                        Borsigstr. 7 (Halle 4)
                    </p>
                    <p>
                        41541 Dormagen
                    </p>
                </div>
                <div className={style.item}>
                    <p>
                        Tel: +49(0)2133 8277363
                    </p>
                    <p>
                        Mobil: +49(0) 163 194 6434
                    </p>
                    <p>
                        Fax: +49(0)2133 8277362
                    </p>
                </div>
                <div className={style.item}>
                    <p>
                        <span>E-Mail: </span><a href="mailto:info@picola.eu">info@picola.eu</a>
                    </p>
                </div>
            </div>
            <div className={style.copyrightContainer}>
                <p>
                    &copy; Copyright {currentYear} Picola
                </p>
            </div>
        </div >
    )
}

export default Footer