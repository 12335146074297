interface NutritionData {
    id: string;
    name: string;
    card_banner: string,
    origin: string;
    size: string;
    values: {
        per100g: {
            energyKJ: number;
            energyKcal: number;
            protein: number;
            carbohydrates: number;
            sugar: number;
            fat: number;
            saturatedFat: number;
            fiber: number;
            sodium: number;
        };
        perPortion: {
            energyKJ: number;
            energyKcal: number;
            protein: number;
            carbohydrates: number;
            sugar: number;
            fat: number;
            saturatedFat: number;
            fiber: number;
            sodium: number;
        };
    };
    produktImages: string[],
}

export const nutritionData: NutritionData[] = [
    {
        id: '1',
        name: "Haselnüsse Naturell",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/naturell.jpg",
        size: '9-11 mm / 11-13 mm / 13-15 mm (Vakuum, Jutesack, Bigbag)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnusse Naturell/naturell_1.jpg",
            "/assets/Produkt Images/Haselnusse Naturell/naturell_2.webp",
            "/assets/Produkt Images/Haselnusse Naturell/naturell_3.webp",
            "/assets/Produkt Images/Haselnusse Naturell/naturell_4.jpg",
        ],
    },
    {
        id: '2',
        name: "	Haselnüsse Blanchiert",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/blanchiert.jpg",
        size: '11-13 mm / 12-14 mm / 13-15 mm (Vakuum, Jutesack, Bigbag)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnusse Blanchiert/blanched_1.webp",
            "/assets/Produkt Images/Haselnusse Blanchiert/blanched_2.webp",
            "/assets/Produkt Images/Haselnusse Blanchiert/blanched_3.jpg",
            "/assets/Produkt Images/Haselnusse Blanchiert/blanched_4.jpg",
        ],
    },
    {
        id: '3',
        name: "	Haselnüsse Geröstet",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/roasted_hazelnut.jpeg",
        size: '13-15 mm (Vakuum)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnusse Gerostet/roasted_1.webp",
            "/assets/Produkt Images/Haselnusse Gerostet/roasted_2.jpg",
            "/assets/Produkt Images/Haselnusse Gerostet/roasted_3.jpg",
            "/assets/Produkt Images/Haselnusse Gerostet/roasted_4.webp",
        ],
    },
    {
        id: '4',
        name: "Haselnüsse Gehackt, Geröstet",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/blanchiert_gehackt_3.jpg",
        size: '2-4 mm (Vakuum, Jutesack, Bigbag)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnusse Blanchiert Gehackt/diced_1.png",
            "/assets/Produkt Images/Haselnusse Blanchiert Gehackt/diced_2.jpeg",
            "/assets/Produkt Images/Haselnusse Blanchiert Gehackt/diced_3.webp",
            "/assets/Produkt Images/Haselnusse Blanchiert Gehackt/diced_4.jpg",
        ],
    },
    {
        id: '5',
        name: "Haselnussgrieß, Geröstet",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/haselnussgriess.jpg",
        size: '0-2 mm (Vakuum, Bigbag)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnussgriess Gerostet/haselnussgriess_gerosted_1.jpg",
            "/assets/Produkt Images/Haselnussgriess Gerostet/haselnussgriess_gerosted_2.jpg",
            "/assets/Produkt Images/Haselnussgriess Gerostet/haselnussgriess_gerosted_3.png",
            "/assets/Produkt Images/Haselnussgriess Gerostet/haselnussgriess_gerosted_4.jpg",
        ],
    },
    {
        id: '6',
        name: "Haselnüsse Gehobelt",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/gehobelt.png",
        size: '0.8-1.0 / 1.0-1.2 mm (Beutel)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnusse Gehobelt/gehobelt_1.jpg",
            "/assets/Produkt Images/Haselnusse Gehobelt/gehobelt_2.jpg",
            "/assets/Produkt Images/Haselnusse Gehobelt/gehobelt_3.jpg",
            "/assets/Produkt Images/Haselnusse Gehobelt/gehobelt_4.jpg",
        ],
    },
    {
        id: '7',
        name: "Haselnuss Paste, Hell / Medium / Dunkel",
        origin: 'Türkei',
        card_banner: "/assets/Produkt Images/Haselnuss Paste/hazelnuss_paste_4.jpg",
        size: '5-20 kg (Eimer) / 200 kg (Fass) / 1000kg (IBC)',
        values: {
            per100g: {
                energyKJ: 2791,
                energyKcal: 677,
                protein: 13.2,
                carbohydrates: 7.6,
                sugar: 4.3,
                fat: 64.1,
                saturatedFat: 5.4,
                fiber: 8.2,
                sodium: 0.01,
            },
            perPortion: {
                energyKJ: 698,
                energyKcal: 169,
                protein: 3.3,
                carbohydrates: 1.9,
                sugar: 1.9,
                fat: 16.0,
                saturatedFat: 1.4,
                fiber: 2.1,
                sodium: 0.01,
            },
        },
        produktImages: [
            "/assets/Produkt Images/Haselnuss Paste/hazelnuss_paste_1.jpg",
            "/assets/Produkt Images/Haselnuss Paste/hazelnuss_paste_2.jpg",
            "/assets/Produkt Images/Haselnuss Paste/hazelnuss_paste_3.jpg",
            "/assets/Produkt Images/Haselnuss Paste/hazelnuss_paste_4.jpg",
        ],
    },
    {
        id: '8',
        name: "Walnüsse.Hälfte / Viertel / Gebrochen",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/walnuts.jpg",
        size: 'Variiert',
        values: {
            per100g: {
                energyKJ: 2738,
                energyKcal: 654,
                protein: 15.2,
                carbohydrates: 13.7,
                sugar: 2.6,
                fat: 65.2,
                saturatedFat: 6.1,
                fiber: 6.7,
                sodium: 2,
            },
            perPortion: {
                energyKJ: 821,
                energyKcal: 196,
                protein: 4.6,
                carbohydrates: 4.1,
                sugar: 0.8,
                fat: 19.6,
                saturatedFat: 1.8,
                fiber: 2.0,
                sodium: 1,
            }
        },
        produktImages: [
            "/assets/Produkt Images/Walnusse Halfte/walnuts_1.jpg",
            "/assets/Produkt Images/Walnusse Halfte/walnuts_2.webp",
            "/assets/Produkt Images/Walnusse Halfte/walnuts_3.webp",
            "/assets/Produkt Images/Walnusse Halfte/walnuts_4.jpg",
        ],
    },
    {
        id: '9',
        name: "Mandeln Gemahlen",
        origin: 'Türkei',
        card_banner: "/assets/Produkte Images/almond.jpg",
        size: "Variiert",
        values: {
            per100g: {
                energyKJ: 2404,
                energyKcal: 579,
                protein: 21.2,
                carbohydrates: 21.6,
                sugar: 4.4,
                fat: 49.9,
                saturatedFat: 3.8,
                fiber: 12.5,
                sodium: 1,
            },
            perPortion: {
                energyKJ: 721,
                energyKcal: 174,
                protein: 6.4,
                carbohydrates: 6.5,
                sugar: 1.3,
                fat: 15.0,
                saturatedFat: 1.1,
                fiber: 3.8,
                sodium: 0,
            }
        },
        produktImages: [
            "/assets/Produkt Images/Mandeln Gerostet/mandeln_gemahlen_1.jpg",
            "/assets/Produkt Images/Mandeln Gerostet/mandeln_gemahlen_2.jpg",
            "/assets/Produkt Images/Mandeln Gerostet/mandeln_gemahlen_3.jpg",
            "/assets/Produkt Images/Mandeln Gerostet/mandeln_gemahlen_4.jpg",
        ],
    }
];