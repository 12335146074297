import React, { useEffect, useState } from 'react';
import style from './CustomMap.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import MapController from './MapController';
import { FiMenu } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { FaLocationDot } from 'react-icons/fa6';

const variants = {
  initial: {
    x: -200,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      staggerChildren: 0.4,
    },
  },
  exit: {
    x: -200,
    opacity: 0,
  },
};

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem',
  border: '3px solid var(--light-brown)',
  position: 'relative' as const,
};

const center = {
  lat: 51.128684557964824,
  lng: 6.804845759115557,
};

interface props {
  isDirectionOpen: boolean;
  setIsDirectionOpen: (isDirectionOpen: boolean) => void;
}

function CustomMap({ isDirectionOpen, setIsDirectionOpen }: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [autoComplete, setAutoComplete] = useState<google.maps.places.AutocompleteService>();
  const [places, setPlaces] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();

  const [distance, setDistance] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');

  const handleClick = () => {
    if (isOpen) {
      // directionsRenderer?.setDirections({ routes: [] });
      // setPlaces([]);
      //   handlePan();
    } else {
      directionsRenderer?.setMap(map);
    }
    // setDistance('');
    // setDuration('');
    setIsOpen((prevIsOpen) => {
      setIsDirectionOpen(!prevIsOpen);
      return !prevIsOpen;
    });
  };

  const handleDirection = async (placeId: string) => {
    // Use PlacesService to get the place details
    const placesService = new google.maps.places.PlacesService(map!);
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        directionsService?.route(
          {
            origin: { lat: center.lat, lng: center.lng },
            destination: { placeId },
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              directionsRenderer?.setDirections(result);
              const route = result?.routes[0];
              if (route && route.legs && route.legs[0]) {
                const leg = route.legs[0];
                setDistance(leg.distance?.text || '');
                setDuration(leg.duration?.text || '');
                setInputText(leg.end_address || inputText);
              }
            } else {
              console.error(`Directions request failed due to ${status}`);
            }
          },
        );
      } else {
        console.error(`Place details request failed due to ${status}`);
      }
    });
  };

  const handlePan = () => {
    map?.panTo(center);
    map?.setZoom(16);
  };

  const handleInputOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);

    if (e.target.value === '') {
      setPlaces([]);
      setDistance('');
      setDuration('');
      directionsRenderer?.setDirections({ routes: [] } as any);
      return;
    }

    autoComplete?.getPlacePredictions({ input: e.target.value }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (predictions !== null) {
          setPlaces(predictions);
        }
      }
    });
  };

  useEffect(() => {
    setIsOpen(isDirectionOpen);
  }, [isDirectionOpen]);

  return (
    <div className={style.container}>
      <div className={style.mapContainer}>
        <APIProvider apiKey={import.meta.env.VITE_API_KEY}>
          <Map style={containerStyle} defaultCenter={center} defaultZoom={16} gestureHandling={'greedy'} mapId={'customMap'}>
            <AdvancedMarker position={center}></AdvancedMarker>
          </Map>

          <MapController
            setAutoComplete={setAutoComplete}
            setMap={setMap}
            setDirectionsService={setDirectionsService}
            setDirectionsRenderer={setDirectionsRenderer}
          ></MapController>
        </APIProvider>

        {!isOpen && (
          <div onClick={handleClick} className={style.containerButton}>
            <span>
              <FiMenu />
            </span>
          </div>
        )}

        <AnimatePresence>
          {isOpen && (
            <motion.div variants={variants} initial="initial" animate="animate" exit="exit" className={style.inputContainer}>
              <div className={style.topSection}>
                <div className={style.directionInfoContainer}>
                  <div className={style.getDirection}>Wegbeschreibung erhalten</div>
                  {distance != '' && (
                    <div className={style.resultContainer}>
                      <div className={style.resultItem}>Distanz: {distance}</div>
                      <div className={style.resultItem}>Dauer: {duration}</div>
                    </div>
                  )}
                </div>
                <div onClick={handleClick} className={style.closeButton}>
                  <span>
                    <IoClose />
                  </span>
                </div>
              </div>

              <div className={style.inputBox}>
                <div className={style.inputDiv}>
                  <input type="text" onChange={(e) => handleInputOnchange(e)} value={inputText} />
                  {places.length > 0 && (
                    <div className={style.placeContainer}>
                      {places.map((place) => (
                        <div onClick={() => handleDirection(place.place_id)} className={style.placeItem} key={place.place_id}>
                          {place.description}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className={`${style.inputDiv} ${style.disabledInput}`}>
                  <input type="text" value={'Picola'} disabled />
                  <span onClick={handlePan}>
                    <FaLocationDot />
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default CustomMap;
