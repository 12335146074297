import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import styles from './Produkte.module.scss';
import { nutritionData } from './ProdukteData';

const Produkte: React.FC = () => {
  const navigate = useNavigate();

  const handleProductClick = (id: string) => {
    navigate(`/produkt/${id}`);
  };

  return (
    <div>
      <Navbar />
      <div style={{ height: '6rem' }}></div>
      <div className={styles.content}>
        {nutritionData.map(product => (
          <div
            key={product.id}
            className={styles.product}
            onClick={() => handleProductClick(product.id)}
          >
            <div className={styles.card}>
              <img src={product.card_banner} alt="" />
              <div className={styles.productInfo}>
                <div className={styles.title}>
                  {product.name}
                </div>
                <div>
                  {product.size}
                </div>
                <div>
                  Herkunftsland: {product.origin}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Produkte;
