import style from './Carousel.module.scss';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { PiDotOutlineFill, PiDotOutlineLight } from 'react-icons/pi';

type props = {
  index: number;
  images: any[];
  setIndex: (prevIndex: number) => void;
};

const CarouselController = ({ index, images, setIndex }: props) => {
  const nextImage = () => {
    if (index === images.length - 1) {
      return;
    }
    setIndex(index + 1);
  };

  const prevImage = () => {
    if (index <= 0) {
      return;
    }
    setIndex(index - 1);
  };

  const toImage = (i: number) => {
    setIndex(i);
  };

  return (
    <div className={style.controller}>
      <div onClick={prevImage}>
        {index === 0 ? (
          <span style={{ color: 'gray', cursor: 'default' }}>
            <FaArrowLeftLong />
          </span>
        ) : (
          <FaArrowLeftLong />
        )}
      </div>
      {images.map((_, i) =>
        index === i ? (
          <div key={i}>
            <PiDotOutlineFill />
          </div>
        ) : (
          <div key={i} onClick={() => toImage(i)}>
            <PiDotOutlineLight />
          </div>
        ),
      )}
      <div onClick={nextImage}>
        {index === images.length - 1 ? (
          <span style={{ color: 'gray', cursor: 'default' }}>
            <FaArrowRightLong />
          </span>
        ) : (
          <FaArrowRightLong />
        )}
      </div>
    </div>
  );
};

export default CarouselController;
