import { useEffect, useRef, useState } from 'react';
import style from './Carousel.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import CarouselController from './CarouselController';
import HazelnutKernelsNatural from '../../assets/HazelnutKernelsNatural.png';
import HazelnutKernelsRoasted from '../../assets/HazelnutKernelsRoasted.webp';
import HazelnutsBlanched from '../../assets/HazelnutsBlanched.png';
import HazelnutsRoastedChopped from '../../assets/HazelnutsRoastedChopped.png';

const images = [
  {
    title: 'Haselnusskerne Naturell',
    img: HazelnutKernelsNatural,
    text: 'Entdecke die pure Kraft der Natur in jeder Nuss! Unsere naturbelassenen Haselnüsse sind reich an Nährstoffen und perfekt für einen gesunden Snack zwischendurch.',
  },
  {
    title: 'Haselnusskerne geröstet',
    img: HazelnutKernelsRoasted,
    text: 'Für einen intensiveren Geschmack! Unsere gerösteten Haselnüsse bieten ein kräftiges Aroma und den perfekten Crunch für jede Mahlzeit oder als Snack.',
  },
  {
    title: 'Haselnüsse Blanchiert',
    img: HazelnutsBlanched,
    text: 'Sanft blanchiert für extra Zartheit! Diese Haselnüsse sind geschält und bereit, deiner Küche eine feine Note zu verleihen – ideal zum Backen und Kochen.',
  },
  {
    title: 'Haselnüsse Gehackt, Geröstet',
    img: HazelnutsRoastedChopped,
    text: 'Perfekt für das gewisse Extra! Unsere gerösteten, gehackten Haselnüsse sorgen für knusprige Textur und vollen Geschmack in deinen Lieblingsgerichten.',
  },
];

const Carousel = () => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prev) => (prev + 1) % images.length);
    }, 5000);
    return () => {
      resetTimeout();
    };
  }, [index]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <div className={style.container}>
      <AnimatePresence mode="wait">
        <motion.div
          className={style.card}
          key={index}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.5 }}
        >
          <div className={style.image}>
            <img src={images[index].img} alt="Image" />
          </div>
          <div className={style.information}>
            <div className={style.title}>{images[index].title}</div>
            <div className={style.description}>{images[index].text}</div>
          </div>
        </motion.div>
      </AnimatePresence>

      <CarouselController index={index} setIndex={setIndex} images={images} />
    </div>
  );
};

export default Carousel;
