import React, { useRef, FormEvent, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import style from './Kontakt.module.scss';
import CustomMap from '../../components/Map/CustomMap';
import emailjs from '@emailjs/browser';
import { Bounce, toast } from 'react-toastify';
import ToastMessage from '../../components/ToastMessage/ToastMessage';

const Kontakt: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [isOpenDirection, setIsOpenDirection] = useState(false);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs.sendForm(import.meta.env.VITE_EMAILJS_SERVICE_ID, import.meta.env.VITE_EMAILJS_TEMPLATE_ID, form.current, import.meta.env.VITE_EMAILJS_USER_ID).then(
        () => {
          if (form.current) {
            form.current.reset();
            toast.success('Email Sent', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
              transition: Bounce,
            });
          }
        },
        (error) => {
          toast.error(`Failed to Send the Message`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          });
          console.log('FAILED...', error.text);
        },
      );
    }
  };

  return (
    <div className={style.container}>
      <ToastMessage />
      <Navbar />
      <div id="customMap"></div>
      <div className={style.navbarHeight} style={{ height: '8rem', backgroundColor: 'var(--beige)' }}></div>
      <CustomMap isDirectionOpen={isOpenDirection} setIsDirectionOpen={setIsOpenDirection} />
      <section className={style.contactSection} style={{ width: '100%', height: '100vh' }}>
        <div className={style.contactInformation}>
          <div>
            <div>
              <h2>Adresse</h2>
              <p>Picola GmbH Borsigstr. 7 (Halle 4) 41541 Dormagen</p>
            </div>
            <div>
              <h2>Anruf</h2>
            </div>
            <p>Tel: +49(0)2133 8277363</p>
            <p>Mobil: +49(0) 163 194 6434</p>
            <p>Fax: +49(0)2133 8277362</p>
          </div>
          <div className={style.buttonContainer}>
            <button
              onClick={() => {
                setIsOpenDirection(!isOpenDirection);
              }}
            >
              <a href="#customMap">Wegbeschreibung erhalten</a>
            </button>
          </div>
        </div>
        <form ref={form} className={style.contactForm} onSubmit={sendEmail}>
          <h1>Kontakt</h1>
          <div className={style.item}>
            <label htmlFor="name">Dein Name (Pflichtfeld)</label>
            <input type="text" id="name" name="user_name" required />
          </div>
          <div className={style.item}>
            <label htmlFor="email">Deine E-Mail-Adresse (Pflichtfeld)</label>
            <input type="email" id="email" name="user_email" required />
          </div>
          <div className={style.item}>
            <label htmlFor="subject">Betreff</label>
            <input type="text" id="subject" name="subject" />
          </div>
          <div className={style.item}>
            <label htmlFor="message">Deine Nachricht</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button className={style.formButton} type="submit">
            Senden
          </button>
        </form>
      </section>

      <Footer />
    </div>
  );
};

export default Kontakt;
