import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Carousel from './components/Carousel/Carousel';

const variants = {
  initial: {
    x: -500,
    y: '-50%',
    opacity: 0,
  },
  animate: {
    x: '-30%',
    opacity: 1,
    y: '-50%',
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

function App() {
  return (
    <>
      <div className="indexContainer">
        <Navbar />
        <motion.div className="indexTitleContainer" variants={variants} initial="initial" animate="animate">
          <motion.h1 className="indexTitle">Knackig, nussig, einzigartig Haselnüsse in ihrer besten Form!</motion.h1>
          <Link to={'/produkte'} className="titleButton">
            <span className="buttonText">Produkte</span>
          </Link>
          <Link to={'/kontakt'} className="titleButton">
            <span className="buttonText">Kontakt</span>
          </Link>
        </motion.div>
      </div>
      <Carousel />

      <Footer />
    </>
  );
}

export default App;
