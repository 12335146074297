import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

interface props {
  setMap: (map: google.maps.Map) => void;
  setAutoComplete: (autoComplete: google.maps.places.AutocompleteService) => void;
  setDirectionsService: (directionsService: google.maps.DirectionsService) => void;
  setDirectionsRenderer: (directionsRenderer: google.maps.DirectionsRenderer) => void;
}

const MapController = ({ setMap, setAutoComplete, setDirectionsService, setDirectionsRenderer }: props) => {
  const mapElement = useMap();
  const placesLib = useMapsLibrary('places');
  const routesLib = useMapsLibrary('routes');

  // This useEffect sends the generated mapElement to the parent component
  useEffect(() => {
    if (!mapElement) {
      return;
    }
    setMap(mapElement);
  }, [mapElement]);

  // This useEffect sends the generated mapElement to the parent component
  useEffect(() => {
    if (!mapElement || !placesLib) {
      return;
    }
    setAutoComplete(new placesLib.AutocompleteService());
  }, [mapElement, placesLib]);

  useEffect(() => {
    if (!mapElement || !routesLib) {
      return;
    }
    setDirectionsService(new routesLib.DirectionsService());
    setDirectionsRenderer(new routesLib.DirectionsRenderer());
  }, [mapElement, routesLib]);

  return <></>;
};

export default MapController;
