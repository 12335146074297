import {
    createBrowserRouter,
} from "react-router-dom";
import App from "../App";
import Kontakt from "./Kontakt/Kontakt";
import UberUns from "./UberUns/UberUns";
import Impressum from "./Impressum/Impressum";
import Produkte from "./Produkte/Produkte";
import Produkt from "./Produkt/Produkt";
import NotFound from "./NotFound/NotFound";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/uber-uns",
        element: <UberUns />
    },
    {
        path: "/produkte",
        element: <Produkte />
    },
    {
        path: "/impressum",
        element: <Impressum />
    },
    {
        path: "/kontakt",
        element: <Kontakt />
    },
    {
        path: "/produkt/:productId",
        element: <Produkt />
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

export default router;
