import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import style from './Impressum.module.scss';

const Impressum = () => {
  return (
    <div>
      <Navbar />
      <div style={{ height: '7rem' }}></div>
      <div className={style.container}>
        <div className={style.textSection}>
          <div className={style.textContainer}>
            <h1 className={style.title}>Impressum</h1>
            <div className={style.content}>
              <h3>Picola GmbH</h3>
              <p>Borsigstr. 7 (Halle 4)</p>
              <p>41541 Dormagen/Germany</p>
              <br />
              <p>Geschäftsführer: Ferit Kesimhasanoglu</p>
              <br />
              <h3>Kontakt</h3>
              <p>Tel: +49 (0) 2133 827 7363</p>
              <p>Fax: +49 (0) 2133 827 7362</p>
              <p>Email: info@picola.eu</p>
              <br />
              <p>Registergericht: Amtsgericht Neuss, HRB 19356</p>
              <br />
              <h3>Umsatzsteuer-ID:</h3>
              <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</p>
              <p>DE 31 297 45 73</p>
              <br />
              <h3>Finanzamt Grevenbroich</h3>
              <br />
              <p>Haftungsausschluss (Disclaimer):</p>
              <p>Haftung für Inhalte</p>
              <br />
              <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
                erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen.
              </p>
              <br />
              <h3>Haftung für Links</h3>
              <p>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
                der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </p>
              <br />
              <h3>Urheberrecht</h3>
              <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                umgehend entfernen.
              </p>
            </div>
          </div>
        </div>
        <div className={style.textSection}>
          <div className={style.textContainer}>
            <h1 className={style.title}>Datenschutzerklärung</h1>
            <div className={style.content}>
              <h3>Verantwortlich im Sinne der Datenschutzgesetzes:</h3>
              <p>Picola GmbH</p>
              <p>Borsigstr. 7 (Halle 4)</p>
              <p>41541 Dormagen/Germany</p>
              <br />
              <h3>Datenschutz</h3>
              <p>
                Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden
                vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.
              </p>
              <br />
              <p>
                Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt
                persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von
                uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.
              </p>
              <br />
              <p>
                Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist
                im Internet nicht möglich.
              </p>
              <br />
              <h3>Datenschutzerklärung Facebook-Plugins (Like-Button)</h3>
              <p>
                Auf unseren Seiten werden Plugins von Facebook genutzt, Anbieter ist die Facebook Inc., 1 Hacker Way, Menlo Park, California 94025,
                USA. Facebook-Plugins sind mit einem Facebook-Logo markiert oder zeigen einen “Like-Button” (“Gefällt mir”). Mehr Informationen über
                Facebook-Plugins finden Sie hier:{' '}
                <a href="http://developers.facebook.com/docs/plugins/">http://developers.facebook.com/docs/plugins/</a>
              </p>
              <br />
              <p>
                Beim Besuch unserer Webseite stellt das Plugin eine direkte Verbindung zwischen Ihrem Webbrowser und dem Facebook-Server her. Dabei
                wird übermittelt, dass Sie sich mit Ihrer IP-Adresse auf unsere Webseite begeben haben. Wird der Facebook “Like-Button” angeklickt,
                während Sie in Ihrem Facebook-Account eingeloggt sind, können bestimmte Inhalte unserer Webseiten mit Ihrem Facebook-Profil verlinkt
                werden. In diesem Prozess ist es Facebook möglich, ihren Besuch auf unseren Webseiten mit ihrem Benutzerkonto zu speichern. Als
                Webseitenbetreiber haben wir keine Informationen darüber, welche Daten übermittelt werden und wie diese genutzt werden. Mehr
                Informationen dazu finden Sie hier:
              </p>
              <br />
              <a href="http://de-de.facebook.com/policy.php">http://de-de.facebook.com/policy.php</a>
              <br />
              <br />
              <p>
                Um zu vermeiden, dass Facebook Ihren Besuch auf unserer Webseite verfolgt, melden Sie sich bitte vor Nutzung unseres Angebots bei
                Facebook ab.
              </p>
              <br />
              <h3>Datenschutzerklärung Google Analytics</h3>
              <p>
                Unsere Website nutzt den Analysedienst Google Analytics, betrieben von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043,
                USA. Google Analytics nutzt “Cookies”, das sind kleine Textdateien, die in Ihrem Browser gespeichert werden und die es ermöglichen,
                die Nutzung unserer Website durch die Besucher zu analysieren. Von den Cookies werden Daten über Ihre Nutzung unserer Webseite
                gesammelt, die normalerweise an einen Google-Server in den USA übertragen und gespeichert werden.
              </p>
              <br />
              <p>
                Wenn die IP-Anonymisierung auf unserer Webseite aktiviert wurde, wird Ihre IP-Adresse von Google innerhalb der Mitgliedstaaten der
                Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vorab gekürzt. In seltenen
                Ausnahmefällen kann die komplette IP-Adresse an einen Google Server in den USA übertragen werden, dann wird diese dort gekürzt. Google
                nutzt diese Daten in unserem Auftrag, um die Nutzung unserer Website auszuwerten, um Berichte über die Webseitenaktivitäten zu
                erstellen sowie um weitere Dienstleistungen anzubieten, die mit der Webseitennutzung und Internetnutzung zusammenhängen. Die von
                Google Analytics erfassten IP-Adressen werden nicht mit anderen Daten von Google korreliert.
              </p>
              <br />
              <p>
                Die Speicherung von Cookies kann durch eine spezielle Einstellung in Ihrem Browser verweigert werden. In diesem Fall ist jedoch die
                Funktionalität unserer Webseite im vollen Umfang nicht gewährleistet. Zusätzlich steht ihnen ein Browser-Plugin zu Verfügung, mit dem
                Sie die Sammlung der auf Ihre Nutzung der Website bezogenen erzeugten Daten und IP-Adressen durch Google verhindern können. Mehr
                Informationen dazu finden Sie hier:
                <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>
              </p>
              <br />
              <h3>Datenschutzerklärung Google +1</h3>
              <p>
                Auf unsere Seiten werden Funktionen von Google +1 genutzt, angeboten von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA
                94043, USA.
              </p>
              <br />
              <p>
                Sammlung und Übertragung von Daten: Sie können die Google +1-Schaltfläche nutzen, um Informationen weltweit zu veröffentlichen. Die
                Google +1-Schaltfläche präsentiert Ihnen und anderen Nutzern individuell abgestimmte Inhalte von Google und deren Partnern. Google
                sammelt Daten über die Informationen, die Sie für einen +1 Inhalt gegeben haben, sowie Daten über die Webseite, die Sie angesehen
                haben, während Sie auf +1 geklickt haben. Ihre +1 Daten können zusammen mit Ihrem Profilnamen und Ihrem Foto in unterschiedlichen
                Google-Diensten, wie Suchergebnissen, Ihrem Google-Profil, sowie auf Webseiten und Werbeanzeigen im Internet eingeblendet werden.
                Informationen über Ihre +1-Aktivitäten werden von Google aufgezeichnet, um die von Ihnen genutzten Google-Dienste zu verbessern. Um
                Google +1 Schaltflächen nutzen zu können, müssen Sie über ein öffentliches Google-Profil verfügen, in dem mindestens der Name des
                Profils enthalten sein muss. Alle Google-Dienste verwenden diesen Profilnamen. Manchmal kann dieser Name auch einen anderen Namen
                ersetzen, den Sie verwendet haben, wenn Inhalte über Ihr Google-Konto mit anderen Nutzern geteilt wurden. Nutzern, die Ihre
                E-Mail-Adresse kennen oder über andere identifizierende Daten von Ihnen verfügen, kann die Identität Ihres Google-Profils angezeigt
                werden.
              </p>
              <br />
              <p>
                Nutzung der gesammelten Daten: Zusätzlich zu der bereits beschriebenen Nutzung unterliegen die von Ihnen bereitgestellten Daten den
                geltenden Google-Datenschutzbestimmungen. Google kann allgemeine Statistiken über die +1-Aktivitäten der Nutzer veröffentlichen, oder
                diese an Nutzer und Partner, wie Publisher, Inserenten oder Partner- Webseiten, weitergeben.
              </p>
              <br />
              <h3>Datenschutzerklärung Pinterest</h3>
              <p>
                Auf unseren Webseiten werden Social Plugins des sozialen Netzwerkes Pinterest verwendet, betrieben von Pinterest Inc., 635 High
                Street, Palo Alto, CA 94301, USA (“Pinterest”). Beim Aufrufen einer Seite, die ein entsprechendes Plugin enthält, wird von Ihrem
                Browser eine direkte Verbindung zu den Pinterest Servern hergestellt. Vom Plugin werden dabei Protokolldaten an den Pinterest Server
                in den USA übermittelt. Diese Protokolldaten können Ihre IP-Adresse, Adresse besuchter Webseiten die ebenfalls Pinterest-Funktionen
                verwenden, Art und Einstellungen des Webbrowsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies
                beinhalten. Mehr Informationen dazu finden Sie hier:
                <a href="https://about.pinterest.com/de/privacy-policy">https://about.pinterest.com/de/privacy-policy</a>
              </p>
              <br />
              <h3>Datenschutzerklärung Twitter</h3>
              <p>
                Auf unseren Webseiten werden Funktionen des Dienstes Twitter verwendet, angeboten von Twitter Inc., 1355 Market Street, Suite 900, San
                Francisco, CA 94103, USA. Wenn Sie Twitter oder die Funktion “Re-Tweet” nutzen, werden die von Ihnen besuchten Internetseiten mit
                Ihrem Twitter-Account verbunden. Andere Nutzer können diese Informationen erhalten und es werden Daten an Twitter gesendet. Als
                Webseitenbetreiber haben wir keine Informationen darüber, welche Daten übermittel und wie diese genutzt werden.
              </p>
              <br />
              <p>
                Mehr Informationen dazu finden Sie hier: <a href="http://twitter.com/privacy">http://twitter.com/privacy</a>
              </p>
              <br />
              <p>
                In den Konto-Einstellungen Ihres Twitter Kontos (unter
                <a href="http://twitter.com/account/settings">http://twitter.com/account/settings</a> ) können Sie Ihre Datenschutzeinstellungen bei
                Twitter ändern.
              </p>
              <br />
              <h3>Auskunft, Löschung, Sperrung</h3>
              <p>
                Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten, deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung
                informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum
                angegebenen Kontaktwege. Für weitere Fragen zum Thema stehen wir Ihnen ebenfalls jederzeit zur Verfügung.
              </p>
              <br />
              <h3>Server-Log-Files</h3>
              <p>
                Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese
                Daten enthalten:
              </p>
              <br />
              <div>
                <ul style={{ marginLeft: '2rem' }}>
                  <li>Browsertyp/ Browserversion</li>
                  <li>Betriebssystem des Rechners</li>
                  <li>Referrer URL</li>
                  <li>Hostname des zugreifenden Rechners</li>
                  <li>Uhrzeit der Serveranfrage</li>
                </ul>
              </div>
              <br />
              <p>
                Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete
                Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.
              </p>
              <br />
              <h3>Cookies</h3>
              <p>
                Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote
                für die Besucher einer Webseite freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
                Computer abgelegt werden und die Ihr Browser verwendet.
              </p>
              <br />
              <p>
                Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere
                Cookies bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch
                wiederzuerkennen.
              </p>
              <br />
              <p>
                Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen.
                Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies
                beim Schließen des Browser aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer Website eingeschränkt sein.
              </p>
              <br />
              <h3>Kontaktformular</h3>
              <p>
                Kontaktdaten, die uns über unser Kontaktformular erreichen, werden inklusive des Inhalts der Anfrage für Bearbeitungszwecke und für
                mögliche Anschlussfragen gespeichert. Diese Daten werden ohne Ihre spezifische Zustimmung nicht weitergegeben.
              </p>
              <br />
              <h3>Änderung der Datenschutzbestimmungen</h3>
              <p>
                Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen
                entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch
                gilt dann automatisch die neue Datenschutzerklärung.
              </p>
              <br />
              <h3>Kontakt zum Datenschutzmitarbeiter</h3>
              <p>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an info@picola.eu mit dem Betreff „Datenschutz“.</p>
              <br />
              <p>
                Diese Widerrufsbelehrung Seite wurde bei <a href="datenschutzerklärungmuster.de">datenschutzerklärungmuster.de</a> erstellt.
              </p>
              <br />
              <p>
                Copyright &copy; 2024 Picola GmbH. Alle Rechte vorbehalten.
                <br />
                Fotografien &copy; by Picola
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Impressum;
