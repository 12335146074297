import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ProduktInfo from "../../components/ProduktInfo/ProduktInfo";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import style from "./Produkt.module.scss";
import { nutritionData } from '../Produkte/ProdukteData';

const Produkt: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    if (!productId) {
        return <Navigate to="/not-found" />;
    }

    if (parseInt(productId) < 1 || parseInt(productId) > 10) {
        return <Navigate to="/not-found" />;
    }

    const product = nutritionData.find(product => product.id === productId);

    return (
        <>
            <Navbar />
            <div style={{ height: '6rem' }} />
            <section className={style.infoSection}>
                <div className={style.imagesContainer}>
                    <div className={style.imageContainer}>
                        <img src={product?.produktImages[0]} alt="" />
                    </div>
                    <div className={style.imageContainer}>
                        <img src={product?.produktImages[1]} alt="" />
                    </div>
                </div>
                <div>
                    <ProduktInfo id={productId} />
                </div>
                <div className={style.imagesContainer}>
                    <div className={style.imageContainer}>
                        <img src={product?.produktImages[2]} alt="" />
                    </div>
                    <div className={style.imageContainer}>
                        <img src={product?.produktImages[3]} alt="" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Produkt;
