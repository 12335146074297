import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import style from './NotFound.module.scss'

const NotFound = () => {
    return (
        <div>
            <Navbar></Navbar>
            <div style={{ "height": "6rem" }}></div>
            <section className={style.container}>
                <div className={style.title}>404</div>
                <div className={style.content}>Nicht gefunden</div>
            </section>

            <Footer></Footer>
        </div>
    )
}

export default NotFound