import style from "./UberUns.module.scss"
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

const UberUns = () => {
    return (
        <div>
            <Navbar />
            <div style={{ "height": "6rem" }}></div>
            <section className={style.textSection}>
                <div className={style.textContainer}>
                    <h1 className={style.title}>Über Uns</h1>

                    <p>
                        Die Picola GmbH, mit Sitz in Dormagen, ist ein junges Unternehmen, dessen Vertrieb, Mitarbeiter und Lieferanten über eine langjährige Erfahrung verfügen und auf professioneller Ebene arbeiten.
                    </p>
                    <p>
                        Unsere Produkte werden überwiegend in Deutschland, als auch in den EU-Staaten und anderen europäischen Ländern verkauft.
                    </p>
                    <p>
                        Die Firma widmet sich überwiegend dem Vertrieb der Haselnüsse; darüber hinaus befinden sich Trockenfrüchte wie Pistazien und Sultaninen im Portfolio.
                    </p>
                    <p>
                        Das erfahrene Personal der Fa. Picola kann den gesamten Herstellungs- und Lieferprozess, insbesondere der Haselnüsse, von der Ernte bis zur Verarbeitung und von der Fabrik bis zum Endverbraucher dank der Kommunikation zu jeder Instanz (Ernte, Zwischenhändler, Fabrik) nach- und zurückverfolgen.
                    </p>
                    <p>
                        Die Fa. Picola setzt im Zusammenhang mit der Ablehnung von Kinderarbeit auf „traceability“;
                        demnach kann die Firma ggf. jedes verkaufte Paket bis auf das Erntefeld zurückverfolgt werden.

                    </p>
                    <p>
                        Die obersten Richtlinien der Fa. Picola sind Qualität, Vertrauen und Respekt gegenüber dem Kunden.
                        Zudem zählen das Einhalten der erwarteten Verpflichtungen, das Anbieten von Qualitätsware zu einem akzeptablen Verkaufspreis und pünktliche Lieferung zu unseren Grundprinzipien.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default UberUns