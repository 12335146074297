import style from './Navbar.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ToggleButton from '../ToggleButton/ToggleButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import logo from "../../assets/logo.png";

const Navbar = () => {
  const [isLinkActive, setIsLinkActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const isPortrait = useMediaQuery({ maxWidth: 505 });

  useEffect(() => {
    if (location.pathname != '/') {
      setIsLinkActive(true);
    } else {
      setIsLinkActive(false);
    }
  }, [location]);

  return (
    <div className={`${style.navbarContainer} ${isLinkActive ? style.activeContainer : ''}`}>
      <div className={style.logo}>
        <h2>
          <NavLink to="/" className={({ isActive }) => (isActive ? `${style.active}` : '')}>
            <img src={logo} alt="" />
          </NavLink>
        </h2>
      </div>

      {isPortrait ? (
        <>
          <motion.div className={style.hamburgerMenu} animate={open ? 'open' : 'closed'}>
            <ToggleButton setOpen={setOpen} />
          </motion.div>

          <AnimatePresence>
            {open ? (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={style.navbarItem}>
                <h2>
                  <NavLink to="/uber-uns" className={({ isActive }) => (isActive ? style.active : '')}>
                    ÜBER UNS
                  </NavLink>
                </h2>
                <h2>
                  <NavLink to="/produkte" className={({ isActive }) => (isActive ? `${style.active}` : '')}>
                    PRODUKTE
                  </NavLink>
                </h2>
                <h2>
                  <NavLink to="/impressum" className={({ isActive }) => (isActive ? style.active : '')}>
                    IMPRESSUM
                  </NavLink>
                </h2>
                <h2>
                  <NavLink to="/kontakt" className={({ isActive }) => (isActive ? style.active : '')}>
                    KONTAKT
                  </NavLink>
                </h2>
              </motion.div>
            ) : (
              <></>
            )}
          </AnimatePresence>
        </>
      ) : (
        <>
          <motion.div className={style.navbarItem}>
            <h2>
              <NavLink to="/uber-uns" className={({ isActive }) => (isActive ? style.active : '')}>
                ÜBER UNS
              </NavLink>
            </h2>
            <h2>
              <NavLink to="/produkte" className={({ isActive }) => (isActive ? `${style.active}` : '')}>
                PRODUKTE
              </NavLink>
            </h2>
            <h2>
              <NavLink to="/impressum" className={({ isActive }) => (isActive ? style.active : '')}>
                IMPRESSUM
              </NavLink>
            </h2>
            <h2>
              <NavLink to="/kontakt" className={({ isActive }) => (isActive ? style.active : '')}>
                KONTAKT
              </NavLink>
            </h2>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default Navbar;
