import React from 'react';
import { nutritionData } from '../../pages/Produkte/ProdukteData';
import style from "./ProduktInfo.module.scss";

interface ProduktInfoProps {
    id: string;
}

const ProduktInfo: React.FC<ProduktInfoProps> = ({ id }) => {
    const product = nutritionData.find(product => product.id === id);

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className={style.container}>
            <h1 className={style.title}>{product.name}</h1>
            <p className={style.productInfo}>Origin: {product.origin}</p>
            <p className={style.productInfo}>Größe: {product.size}</p>

            <table>
                <thead>
                    <th>Durchschnittliche Nährwerte</th>
                    <th>Pro 100g</th>
                    <th>1 Portion (25g)</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Brennwert
                        </td>
                        <td>
                            {product.values.per100g.energyKJ} KJ
                            <br />
                            {product.values.per100g.energyKcal} Kcal
                        </td>
                        <td>
                            {product.values.perPortion.energyKJ} KJ
                            <br />
                            {product.values.perPortion.energyKcal} Kcal
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Eiweiß
                        </td>
                        <td>
                            {product.values.per100g.protein}
                        </td>
                        <td>
                            {product.values.perPortion.protein}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kohlenhydrate
                        </td>
                        <td>
                            {product.values.per100g.carbohydrates}
                        </td>
                        <td>
                            {product.values.perPortion.carbohydrates}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            davon Zucker
                        </td>
                        <td>
                            {product.values.per100g.sugar}
                        </td>
                        <td>
                            {product.values.perPortion.sugar}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fett
                        </td>
                        <td>
                            {product.values.per100g.fat}
                        </td>
                        <td>
                            {product.values.perPortion.fat}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            davon gesättigte
                            Fettsäuren
                        </td>
                        <td>
                            {product.values.per100g.saturatedFat}
                        </td>
                        <td>
                            {product.values.perPortion.saturatedFat}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Ballaststoffe
                            Fettsäuren
                        </td>
                        <td>
                            {product.values.per100g.fiber}
                        </td>
                        <td>
                            {product.values.perPortion.fiber}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Natrium
                        </td>
                        <td>
                            {product.values.per100g.sodium}
                        </td>
                        <td>
                            {product.values.perPortion.sodium}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ProduktInfo;
